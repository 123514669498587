import Footer from "./footer";
import Header from "./header";
import React, { useEffect, useState } from "react";
import ManageAccount from "../Employee/manageAccount";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  requestEmpLogin,
  requestFormField,
  requestAddJob,
} from "../Redux/actions";
import WOW from "wowjs";
import Swal from "sweetalert2";
import Breadcrumbs from "../Section/breadcrumbsSection";

function PostJob(props) {
  useEffect(() => {
    new WOW.WOW().init();
    localStorage.removeItem("link");
  }, []);

  const mystyle = {
    color: "#D10000",
    backgroundColor: "#FFD2D2",
    padding: "3px 10px",
    border: "1px solid red",
    borderRadius: "5px",
    marginTop: "5px",
  };

  const [data, setData] = useState({});
  const [selectedskill, setselectedskill] = useState([]);
  const [selectedtag, setselectedtag] = useState([]);
  const [SalaryPeriod, setSalaryPeriod] = useState([]);
  const [career_levels, setcareer_levels] = useState([]);
  const [categories, setcategories] = useState([]);
  const [currencies, setcurrencies] = useState([]);
  const [degree_levels, setdegree_levels] = useState([]);
  const [functional_areas, setfunctional_areas] = useState([]);
  const [job_shifts, setjob_shifts] = useState([]);
  const [skills, setskills] = useState([]);
  const [tags, settags] = useState([]);
  const [types, settypes] = useState([]);
  const [position, setposition] = useState([]);

  const [errorTitle, seterrorTitle] = useState("");
  const [errorType, seterrorType] = useState("");
  const [errorCategory, seterrorCategory] = useState("");
  const [errorGender, seterrorGender] = useState("");
  const [errorExpiry, seterrorExpiry] = useState("");
  const [errorFrom, seterrorFrom] = useState("");
  const [errorTo, seterrorTo] = useState("");
  const [errorCurrency, seterrorCurrency] = useState("");
  const [errorPeriod, seterrorPeriod] = useState("");
  const [errorCareer, seterrorCareer] = useState("");
  const [errorShift, seterrorShift] = useState("");
  const [errorDegree, seterrorDegree] = useState("");
  const [errorFunctional, seterrorFunctional] = useState("");
  const [errorPosition, seterrorPosition] = useState("");
  const [errorDesc, seterrorDesc] = useState("");
  const [errorName, seterrorName] = useState("");
  const [errorEmail, seterrorEmail] = useState("");
  const [errorVacancy, seterrorVacancy] = useState("");
  const [error, setError] = useState(false);

  const navigate = useNavigate();
  const [emp, setEmp] = useState({});

  useEffect(() => {
    let empLoginData = props.employee.empLoginData;
    if (empLoginData !== undefined) {
      if (empLoginData?.data?.status == "success") {
        setEmp(empLoginData.data.data);
        props.requestFormField({
          token: empLoginData.data.data.token,
        });
      } else {
        localStorage.setItem("link", "/postJob");
        navigate("/emplogin");
      }
    } else {
      localStorage.setItem("link", "/postJob");
      navigate("/emplogin");
    }
  }, [props.employee.empLoginData]);

  useEffect(() => {
    let formfieldData = props.employee.formfieldData;
    console.log(props.employee.formfieldData)
    if (formfieldData !== undefined) {
      if (formfieldData?.data?.status == "success") {
        setSalaryPeriod(formfieldData.data.data.SalaryPeriod);
        setcareer_levels(formfieldData.data.data.career_levels);
        setcategories(formfieldData.data.data.categories);
        setcurrencies(formfieldData.data.data.currencies);
        setdegree_levels(formfieldData.data.data.degree_levels);
        setfunctional_areas(formfieldData.data.data.functional_areas);
        setjob_shifts(formfieldData.data.data.job_shifts);
        setskills(formfieldData.data.data.skills);
        settags(formfieldData.data.data.tags);
        settypes(formfieldData.data.data.types);
        setposition(formfieldData.data.data.position);
      }
    }
  }, [props.employee.formfieldData]);

  function onChangeData(e) {
    if (e.target.type === 'radio') {
      setData((data) => ({
        ...data,
        [e.target.name]: parseInt(e.target.value),
      }));
    } else {
      setData((data) => ({
        ...data,
        [e.target.name]: e.target.value,
      }));
    }
  }

  function onChangeSkill(e) {
    selectedskill.push(e.target.value);
  }

  function validateTitle() {
    let formIsValid = false;
    if (!data["title"]) {
      formIsValid = false;
      seterrorTitle("*Enter job title.");
    } else if (typeof data["title"] === "undefined") {
      formIsValid = false;
      seterrorTitle("*Enter job title.");
    } else {
      formIsValid = true;
      seterrorTitle("");
    }
    return formIsValid;
  }
  function validateType() {
    let formIsValid = false;
    if (data["type"] === "0") {
      formIsValid = false;
      seterrorType("*Select job type.");
    } else if (typeof data["type"] === "undefined") {
      formIsValid = false;
      seterrorType("*Select job type.");
    } else {
      formIsValid = true;
      seterrorType("");
    }
    return formIsValid;
  }
  function validateCategory() {
    let formIsValid = false;
    if (data["category"] === "0") {
      formIsValid = false;
      seterrorCategory("*Select job category.");
    } else if (typeof data["category"] === "undefined") {
      formIsValid = false;
      seterrorCategory("*Select job category.");
    } else {
      formIsValid = true;
      seterrorCategory("");
    }
    return formIsValid;
  }
  function validateGender() {
    let formIsValid = false;
    if (typeof data["gender"] === "undefined") {
      formIsValid = false;
      seterrorGender("*Select gender for job.");
    } else {
      formIsValid = true;
      seterrorGender("");
    }
    return formIsValid;
  }
  function validateExpiry() {
    let formIsValid = false;
    var Today = new Date();
    if (typeof data["expiry_date"] !== "undefined") {
      if (new Date(data["expiry_date"]).getTime() <= Today.getTime()) {
        formIsValid = false;
        seterrorExpiry("*Please select proper date.");
      } else {
        formIsValid = true;
        seterrorExpiry("");
      }
    } else {
      formIsValid = true;
      seterrorExpiry("");
    }
    return formIsValid;
  }
  function validateFrom() {
    let formIsValid = false;
    if (data["salary_from"] === undefined) {
      formIsValid = false;
      seterrorFrom("*Enter minimum salary.");
    } 
    else if (data["salary_from"] <= 0) {
      formIsValid = false;
      seterrorFrom("*Minimum salary shold be more than 0.");
    } else {
      formIsValid = true;
      seterrorFrom("");
    }
    return formIsValid;
  }
  function validateTo() {
    let formIsValid = false;
    if (data["salary_to"] === undefined) {
      formIsValid = false;
      seterrorTo("*Enter maximum salary.");
    } 
    else if (data["salary_to"] <= data["salary_from"]) {
      formIsValid = false;
      seterrorTo("*Maximum salary shold be more than Minimum salary.");
    } else {
      formIsValid = true;
      seterrorTo("");
    }
    return formIsValid;
  }
  function validateCurrency() {
    let formIsValid = false;
    if (data["currency"] === "0") {
      formIsValid = false;
      seterrorCurrency("*Select salary currency.");
    } 
    else if (typeof data["currency"] === "undefined") {
      formIsValid = false;
      seterrorCurrency("*Select salary currency.");
    } else {
      formIsValid = true;
      seterrorCurrency("");
    }
    return formIsValid;
  }
  function validatePeriod() {
    let formIsValid = false;
    if (data["salary_period"] === "0") {
      formIsValid = false;
      seterrorPeriod("*Select salary period.");
    } else if (typeof data["salary_period"] === "undefined") {
      formIsValid = false;
      seterrorPeriod("*Select salary period.");
    } else {
      formIsValid = true;
      seterrorPeriod("");
    }
    return formIsValid;
  }
  function validateCareer() {
    let formIsValid = false;
    if (data["career_level"] === "0") {
      formIsValid = false;
      seterrorCareer("*Select career level.");
    } else if (typeof data["career_level"] === "undefined") {
      formIsValid = false;
      seterrorCareer("*Select career level.");
    } else {
      formIsValid = true;
      seterrorCareer("");
    }
    return formIsValid;
  }
  function validateShift() {
    let formIsValid = false;
    if (data["shift"] === "0") {
      formIsValid = false;
      seterrorShift("*Select job shift.");
    } else if (typeof data["shift"] === "undefined") {
      formIsValid = false;
      seterrorShift("*Select job shift.");
    } else {
      formIsValid = true;
      seterrorShift("");
    }
    return formIsValid;
  }
  function validateDegree() {
    let formIsValid = false;
    if (data["degree_level"] === "0") {
      formIsValid = false;
      seterrorDegree("*Select degree level.");
    } else if (typeof data["degree_level"] === "undefined") {
      formIsValid = false;
      seterrorDegree("*Select degree level.");
    } else {
      formIsValid = true;
      seterrorDegree("");
    }
    return formIsValid;
  }
  function validateFunctional() {
    let formIsValid = false;
    if (data["functional_area"] === "0") {
      formIsValid = false;
      seterrorFunctional("*Select functional area.");
    } else if (typeof data["functional_area"] === "undefined") {
      formIsValid = false;
      seterrorFunctional("*Select functional area.");
    } else {
      formIsValid = true;
      seterrorFunctional("");
    }
    return formIsValid;
  }
  function validatePosition() {
    let formIsValid = false;
    if (data["position"] === "0") {
      formIsValid = false;
      seterrorPosition("*Select job position.");
    } else if (typeof data["position"] === "undefined") {
      formIsValid = false;
      seterrorPosition("*Select job position.");
    } else {
      formIsValid = true;
      seterrorPosition("");
    }
    return formIsValid;
  }
  function validateDesc() {
    let formIsValid = false;
    if (!data["description"]) {
      formIsValid = false;
      seterrorDesc("*Enter job description.");
    } else if (typeof data["description"] === "undefined") {
      formIsValid = false;
      seterrorDesc("*Enter job description.");
    } else {
      formIsValid = true;
      seterrorDesc("");
    }
    return formIsValid;
  }
  // function validateName() {
  //   let formIsValid = false;
  //   if (!data["Recruiter_name"]) {
  //     formIsValid = false;
  //     seterrorName("*Enter recruiter name.");
  //   } else if (typeof data["Recruiter_name"] !== "undefined") {
  //     if (!data["Recruiter_name"].match(/^[a-zA-Z][a-zA-Z\s]*$/)) {
  //       formIsValid = false;
  //       seterrorName("*Please enter Alphabet characters only.");
  //     } else {
  //       formIsValid = true;
  //       seterrorName("");
  //     }
  //   }
  //   return formIsValid;
  // }
  // function validateEmail() {
  //   let formIsValid = false;
  //   if (!data["Recruiter_email"]) {
  //     formIsValid = false;
  //     seterrorEmail("*Enter recruiter E-mail ID.");
  //   } else if (typeof data["Recruiter_email"] !== "undefined") {
  //     if (
  //       !data["Recruiter_email"].match(
  //         /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  //       )
  //     ) {
  //       formIsValid = false;
  //       seterrorEmail("*Please enter valid E-mail ID.");
  //     } else {
  //       formIsValid = true;
  //       seterrorEmail("");
  //     }
  //   }
  //   return formIsValid;
  // }
  function validateVacancy() {
    let formIsValid = false;
    if (data["vacancy"] === undefined) {
      formIsValid = false;
      seterrorVacancy("*Enter job vacancy.");
    } else if (data["vacancy"] <= 0) {
      formIsValid = false;
      seterrorVacancy("*Vacancy shold be more than 0.");
    } else {
      formIsValid = true;
      seterrorVacancy("");
    }
    return formIsValid;
  }
  function validateForm() {
    let Title = validateTitle();
    let Type = validateType();
    let Category = validateCategory();
    // let Gender = validateGender();
    // let Expiry = validateExpiry();
    let From = validateFrom();
    let To = validateTo();
    let Currency = validateCurrency();
    let Period = validatePeriod();
    let Career = validateCareer();
    let Shift = validateShift();
    let Degree = validateDegree();
    let Functional = validateFunctional();
    let Position = validatePosition();
    let Desc = validateDesc();
    // let Name = validateName();
    // let Email = validateEmail();
    let Vacancy = validateVacancy();

    let valid =
      Title &&
      Type &&
      Category &&
      // Gender &&
      // Expiry &&
      From &&
      To &&
      Currency &&
      Period &&
      Career &&
      Shift &&
      Degree &&
      Functional &&
      Position &&
      Desc &&
      // Name &&
      // Email &&
      Vacancy;
    return valid;
  }

  function submitForm(e) {
    e.preventDefault();
    if (validateForm()) {
      props.requestAddJob({
        token: emp.token,
        data: {
          company_id: emp.id,
          title: data.title,
          type: data.type,
          category: data.category,
          skill: selectedskill,
          gender: data.gender,
          // expiry_date: data.expiry_date,
          salary_from: data.salary_from,
          salary_to: data.salary_to,
          currency: data.currency,
          salary_period: data.salary_period,
          career_level: data.career_level,
          shift: data.shift,
          tag: data.tag,
          degree_level: data.degree_level,
          functional_area: data.functional_area,
          position: data.position,
          experience: data.position,
          description: data.description,
          // Recruiter_name: data.Recruiter_name,
          // Recruiter_email: data.Recruiter_email,
          vacancy: data.vacancy,
        },
      });
      setError(false)
    }else{
      setError(true)
    }
  }

  useEffect(() => {
    if (error) {
      if(errorTitle){
        document.getElementById("title").focus();
      }else if(errorVacancy){
        document.getElementById("vacancy").focus();
      }else if(errorType){
        document.getElementById("type").focus();
      }else if(errorCategory){
        document.getElementById("category").focus();
      }else if(errorGender){
        document.getElementById("genderMale").focus();
      }else if(errorExpiry){
        document.getElementById("expiry_date").focus();
      }else if(errorFrom){
        document.getElementById("salary_from").focus();
      }else if(errorTo){
        document.getElementById("salary_to").focus();
      }else if(errorCurrency){
        document.getElementById("currency").focus();
      }else if(errorPeriod){
        document.getElementById("salary_period").focus();
      }else if(errorCareer){
        document.getElementById("career_level").focus();
      }else if(errorShift){
        document.getElementById("shift").focus();
      }else if(errorPosition){
        document.getElementById("position").focus();
      }else if(errorDegree){
        document.getElementById("degree_level").focus();
      }else if(errorFunctional){
        document.getElementById("functional_area").focus();
      }else if(errorDesc){
        document.getElementById("description").focus();
      }
      // else if(errorName){
      //   document.getElementById("Recruiter_name").focus();
      // }else if(errorEmail){
      //   document.getElementById("Recruiter_email").focus();
      // }
      setError(false)
    }
  },[error]);

  useEffect(() => {
    let empAddJobData = props.employee.empAddJobData;
    if (empAddJobData !== undefined) {
      if (empAddJobData?.data?.status == "success") {
        Swal.fire("Good job!", "Job added successfully.", "success");
        props.employee.empAddJobData = undefined;
        navigate("/manageJobs");
      } else {
        Swal.fire("Error!", `Something went wrong while posting job.`, "error");
        props.employee.empAddJobData = undefined;
      }
    }
  }, [props.employee.empAddJobData]);
  return (
    <>
      <Header />
      <Breadcrumbs title="Post Job" />
      <div class="manage-jobs section">
        <div class="container">
          <div class="alerts-inner">
            <div class="row">
              <ManageAccount name="PostJob" />

              <div class="col-lg-8 col-12">
                <div class="job-post">
                  <div class="job-information">
                    <h3 class="title">Job Information</h3>
                    <form class="forms-sample">
                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Job title*</label>
                            <input
                              class="form-control"
                              type="text"
                              name="title"
                              id="title"
                              value={data.title}
                              onBlur={validateTitle}
                              onChange={onChangeData}
                              placeholder=""
                            />
                            {errorTitle && (
                              <div style={mystyle}>{errorTitle}</div>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Job Vacancy*</label>
                            <input
                              class="form-control"
                              type="number"
                              name="vacancy"
                              id="vacancy"
                              value={data.vacancy}
                              onBlur={validateVacancy}
                              onChange={onChangeData}
                              placeholder=""
                            />
                            {errorVacancy && (
                              <div style={mystyle}>{errorVacancy}</div>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Job Types*</label>
                            <select
                              class="select"
                              name="type"
                              id="type"
                              value={data.type}
                              onBlur={validateType}
                              onChange={onChangeData}
                            >
                              <option value="0">Select Job Type</option>
                              {types.map((option) => (
                                <option value={option.id}>{option.name}</option>
                              ))}
                            </select>
                            {errorType && (
                              <div style={mystyle}>{errorType}</div>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Job Category*</label>
                            <select
                              class="select"
                              name="category"
                              id="category"
                              value={data.category}
                              onBlur={validateCategory}
                              onChange={onChangeData}
                            >
                              <option value="0">Select Job Category</option>
                              {categories.map((option) => (
                                <option value={option.id}>{option.name}</option>
                              ))}
                            </select>
                            {errorCategory && (
                              <div style={mystyle}>{errorCategory}</div>
                            )}
                          </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                          {/* <div style={{ color: "black" }}>
                            <label for="gender" class="label">
                              Gender
                            </label>
                            <br />
                            <br />
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                style={{ margin: "0px" }}
                                id="genderMale"
                                name="gender"
                                value="1"
                                onBlur={validateGender}
                                onChange={onChangeData}
                              />
                              <label
                                class="form-check-label"
                                for="inlineRadio1"
                              >
                                Male
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                style={{ margin: "0px" }}
                                id="genderFemale"
                                name="gender"
                                value="0"
                                onBlur={validateGender}
                                onChange={onChangeData}
                              />
                              <label
                                class="form-check-label"
                                for="inlineRadio2"
                              >
                                Female
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                style={{ margin: "0px" }}
                                id="genderAny"
                                name="gender"
                                value="2"
                                onBlur={validateGender}
                                onChange={onChangeData}
                              />
                              <label
                                class="form-check-label"
                                for="inlineRadio3"
                              >
                                Any
                              </label>
                            </div>
                            {errorGender && (
                              <div style={mystyle}>{errorGender}</div>
                            )}
                          </div> */}
                        </div>
                        <div class="col-lg-6 col-md-6">
                          {/* <div class="form-group">
                            <label>Expiry Date</label>
                            <input
                              class="form-control"
                              type="date"
                              name="expiry_date"
                              id="expiry_date"
                              value={data.expiry_date}
                              onBlur={validateExpiry}
                              onChange={onChangeData}
                              placeholder=""
                            />
                            {errorExpiry && (
                              <div style={mystyle}>{errorExpiry}</div>
                            )}
                          </div> */}
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Salary From*</label>
                            <input
                              class="form-control"
                              type="number"
                              name="salary_from"
                              id="salary_from"
                              value={data.salary_from}
                              onBlur={validateFrom}
                              onChange={onChangeData}
                              placeholder=""
                            />
                            {errorFrom && (
                              <div style={mystyle}>{errorFrom}</div>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Salary To*</label>
                            <input
                              class="form-control"
                              type="number"
                              name="salary_to"
                              id="salary_to"
                              value={data.salary_to}
                              onBlur={validateTo}
                              onChange={onChangeData}
                              placeholder=""
                            />
                            {errorTo && <div style={mystyle}>{errorTo}</div>}
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Currency*</label>
                            <select
                              class="select"
                              name="currency"
                              id="currency"
                              value={data.currency}
                              onBlur={validateCurrency}
                              onChange={onChangeData}
                            >
                              <option value="0">Select Currency</option>
                              {currencies.map((option) => (
                                <option value={option.id}>
                                  {option.currency_name}
                                </option>
                              ))}
                            </select>
                            {errorCurrency && (
                              <div style={mystyle}>{errorCurrency}</div>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Salary Period*</label>
                            <select
                              class="select"
                              name="salary_period"
                              id="salary_period"
                              value={data.salary_period}
                              onBlur={validatePeriod}
                              onChange={onChangeData}
                            >
                              <option value="0">Select Salary Period</option>
                              {SalaryPeriod.map((option) => (
                                <option value={option.id}>
                                  {option.period}
                                </option>
                              ))}
                            </select>
                            {errorPeriod && (
                              <div style={mystyle}>{errorPeriod}</div>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Career Level*</label>
                            <select
                              class="select"
                              name="career_level"
                              id="career_level"
                              value={data.career_level}
                              onBlur={validateCareer}
                              onChange={onChangeData}
                            >
                              <option value="0">Select Career Level</option>
                              {career_levels.map((option) => (
                                <option value={option.id}>
                                  {option.level}
                                </option>
                              ))}
                            </select>
                            {errorCareer && (
                              <div style={mystyle}>{errorCareer}</div>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Shift*</label>
                            <select
                              class="select"
                              name="shift"
                              id="shift"
                              value={data.shift}
                              onBlur={validateShift}
                              onChange={onChangeData}
                            >
                              <option value="0">Select Shift</option>
                              {job_shifts.map((option) => (
                                <option value={option.id}>{option.name}</option>
                              ))}
                            </select>
                            {errorShift && (
                              <div style={mystyle}>{errorShift}</div>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Position*</label>
                            <select
                              class="select"
                              name="position"
                              id="position"
                              value={data.position}
                              onBlur={validatePosition}
                              onChange={onChangeData}
                            >
                              <option value="0">Select Position</option>
                              {position.map((option) => (
                                <option value={option.id}>{option.name}</option>
                              ))}
                            </select>
                            {errorPosition && (
                              <div style={mystyle}>{errorPosition}</div>
                            )}
                          </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Degree Level</label>
                            <select
                              class="select"
                              name="degree_level"
                              id="degree_level"
                              value={data.degree_level}
                              onBlur={validateDegree}
                              onChange={onChangeData}
                            >
                              <option value="0">Select Degree</option>
                              {degree_levels.map((option) => (
                                <option value={option.id}>
                                  {option.level}
                                </option>
                              ))}
                            </select>
                            {errorDegree && (
                              <div style={mystyle}>{errorDegree}</div>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Functional Area</label>
                            <select
                              class="select"
                              name="functional_area"
                              id="functional_area"
                              value={data.functional_area}
                              onBlur={validateFunctional}
                              onChange={onChangeData}
                            >
                              <option value="0">Select Functional Area</option>
                              {functional_areas.map((option) => (
                                <option value={option.id}>{option.name}</option>
                              ))}
                            </select>
                            {errorFunctional && (
                              <div style={mystyle}>{errorFunctional}</div>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Experience</label>
                            <input
                              class="form-control"
                              type="number"
                              name="experience"
                              min="0"
                              value={data.experience}
                              onChange={onChangeData}
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Skill*</label>
                            <select
                              class="select"
                              style={{ height: "200px" }}
                              name="skill"
                              onChange={onChangeSkill}
                              multiple
                            >
                              {skills.map((option) => (
                                <option value={option.id}>{option.name}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Tag</label>
                            <select
                              class="select"
                              style={{ height: "200px" }}
                              name="tag"
                              value={data.tag}
                              onChange={onChangeData}
                              multiple
                            >
                              {tags.map((option) => (
                                <option value={option.id}>{option.name}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="form-group">
                            <label>Job Description*</label>
                            <textarea
                              class="form-control"
                              rows="5"
                              name="description"
                              id="description"
                              value={data.description}
                              onChange={onChangeData}
                              onBlur={validateDesc}
                              placeholder=""
                            ></textarea>
                            {errorDesc && (
                              <div style={mystyle}>{errorDesc}</div>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* <h3 class="title">Recruiter Information</h3>
                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Full Name</label>
                            <input
                              class="form-control"
                              type="text"
                              name="Recruiter_name"
                              id="Recruiter_name"
                              value={data.Recruiter_name}
                              onChange={onChangeData}
                              onBlur={validateName}
                              placeholder=""
                            />
                            {errorName && (
                              <div style={mystyle}>{errorName}</div>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Email</label>
                            <input
                              class="form-control"
                              type="email"
                              name="Recruiter_email"
                              id="Recruiter_email"
                              value={data.Recruiter_email}
                              onChange={onChangeData}
                              onBlur={validateEmail}
                              placeholder=""
                            />

                            {errorEmail && (
                              <div style={mystyle}>{errorEmail}</div>
                            )}
                          </div>
                        </div>
                      </div> */}
                    </form>
                    <div class="col-lg-12 button">
                      <button class="btn" onClick={submitForm}>
                        Post a Job
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
const mapStateToProps = (state) => {
  return { employee: state.employee, candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { requestEmpLogin, requestFormField, requestAddJob },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PostJob);

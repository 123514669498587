import { useEffect } from "react";

import WOW from "wowjs";

function TalentSection() {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  return (
    <section class="section bg-lgray">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-7 col-12 talent mb-3 mb-lg-0">
            <img src="https://exalogic-store.s3.us-east-2.amazonaws.com/realcube_cityscape/talent-attraction.png"/>
            </div>
          <div class="col-lg-5 col-12">
            <h2 className="fw-semibold mb-3 font-36">Attract Top-tier Talent From Around The Globe.</h2>
            <p  className="font-14 fw-semibold lh-21">
            Our goal is to attract top-tier professionals from across the world, fostering a diverse and innovative workforce. Through strategic outreach and modern connectivity, we're creating a global community of exceptional minds, driving growth and innovation for our organization."
            </p>
            <a href="/postJob" className="btn-talent-job mt-4">Post a Job</a>
          </div>
        </div>
      </div>
    </section>
  );
}
export default TalentSection;

import Footer from "../Components/footer";
import Header from "../Components/header";
import { RWebShare } from "react-web-share";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  requestEmpLogin,
  requestLogin,
  requestCountLastweekJob,
  requestRecentlyJob,
  requestCategory,
  requestAddBookmark,
  userLogout,
} from "../Redux/actions";
import WOW from "wowjs";
import img from "../images/emp.jpg";
import image from "../images/extraLogo.png";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import Swal from "sweetalert2";
import About from "../Section/aboutUsSection";
import Apply from "../Section/applyProcessSection";
import Jobcategory from "../Section/jobCategorySection";
import Client from "../Section/clientLogoSection";
import CounterSection from "../Section/counterSection";
import SubscribeSection from "../Section/subscribeSection";
import TalentSection from "../Section/talentSection";
// import TestiSliderSection from "../Section/testiSliderSection";
import {currencyFormatter} from "./currencyFormatter";


function Home(props) {
  // console.log(props);
  useEffect(() => {
    new WOW.WOW().init();
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const [user, setUSer] = useState({});
  const [emp, setEmp] = useState({});
  const [jobcount, setjobcount] = useState(0);
  const [jobs, setjobs] = useState([]);

  useEffect(() => {
    props.requestCountLastweekJob();
    props.requestCategory();
    props.requestRecentlyJob({});
  }, []);

  useEffect(() => {
    let loginData = props.candidate.loginData;
    if (loginData !== undefined) {
      if (loginData?.data?.status == "success") {
        setUSer(loginData.data.data);
        props.requestRecentlyJob({
          token: loginData.data.data.token,
        });
      }
    } else {
      props.requestRecentlyJob({});
    }
  }, []);

  useEffect(() => {
    let empLoginData = props.employee.empLoginData;
    if (empLoginData !== undefined) {
      if (empLoginData?.data?.status == "success") {
        setEmp(empLoginData.data.data);
      }
    }
  }, [props.employee.empLoginData]);

  useEffect(() => {
    let loginData = props.candidate.loginData;
    if (loginData !== undefined) {
      if (loginData?.data?.status == "success") {
        setUSer(loginData.data.data);
      }
    }
  }, [props.candidate.loginData]);

  useEffect(() => {
    let lastweekjob = props.candidate.lastWeekJobCount;
    if (lastweekjob !== undefined) {
      if (lastweekjob?.data?.status == "success") {
        setjobcount(lastweekjob.data.data);
      }
    }
  }, [props.candidate.lastWeekJobCount]);

  useEffect(() => {
    let recentlyjob = props.candidate.recentlyAddedJobData;
    if (recentlyjob !== undefined) {
      if (recentlyjob?.data?.status == "success") {
        setjobs(recentlyjob.data.data);
      }
    }
  }, [props.candidate.recentlyAddedJobData]);

  function bookmarkJobs(id) {
    if (user.id) {
      props.requestAddBookmark({
        token: user.token,
        id: id,
        data: {},
      });
    } else {
      localStorage.setItem("link", `/home`);
      navigate("/login");
    }
  }

  useEffect(() => {
    if (user.id) {
      let addBookmarkedData = props.candidate.addBookmarkedData;
      if (addBookmarkedData !== undefined) {
        if (addBookmarkedData?.data?.status == "success") {
          Swal.fire("Good job!", "Bookmarked for Job successfully.", "success");
          props.candidate.addBookmarkedData = undefined;
          let loginData = props.candidate.loginData;
          if (loginData !== undefined) {
            if (loginData?.data?.status == "success") {
              setUSer(loginData.data.data);
              props.requestRecentlyJob({
                token: loginData.data.data.token,
              });
            }
          } else {
            props.requestRecentlyJob();
          }
        } else {
          Swal.fire("Error!", "Already bookmarked for the job.", "error");
          props.candidate.addBookmarkedData = undefined;
        }
      }
    } else {
      let addBookmarkedData = props.candidate.addBookmarkedData;
      if (addBookmarkedData !== undefined) {
        if (addBookmarkedData?.data?.status == "Token is Expired") {
          props.userLogout();
        }
      }
    }
  }, [props.candidate.addBookmarkedData]);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 300,
  });
  const [categories, setCategories] = useState([]);
  const [items, setitems] = useState([]);
  const [state, setState] = useState({
    suggestions: [],
    text: "",
  });

  const [isSticky, setSticky] = useState(false);



  useEffect(() => {
    let categoryData = props.candidate.categoryData;
    if (categoryData) {
      if (categoryData?.data?.status == "success") {
        setCategories(categoryData.data.data.categories);
      }
    }
  }, [props.candidate.categoryData]);
  useEffect(() => {
    {
      categories.map((item1) => {
        if (items.indexOf(item1.name) === -1) {
          items.push(item1.name);
        }
      });
    }
  }, [categories]);
  function suggestionSelected(value) {
    setState(() => ({
      text: value,
      suggestions: [],
    }));
  }

  const onTextChanged = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = items.sort().filter((v) => regex.test(v));
    }
    setState(() => ({ suggestions, text: value }));
  };

  function renderSuggestionsTitle() {
    const { suggestions } = state;
    if (suggestions.length === 0) {
      return null;
    }
    return (
      <div className="srchList">
        <ul>
          {suggestions.map((item) => (
            <li
              onClick={() => suggestionSelected(item)}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description }) =>
    () => {
      setValue(description, false);
      clearSuggestions();
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;
      const text = main_text + ", " + secondary_text;
      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          {text.length > 20 ? text.substring(0, 20) + "..." : text}
        </li>
      );
    });

  function submitForm(e) {
    e.preventDefault();
    localStorage.setItem("jobKeyword", state.text);
    localStorage.setItem("jobLocation", value);
    navigate("/browseJobs");
  }

  function commentFunction(id) {
    localStorage.setItem("comment", "comment");
    navigate(`/jobDetails/${id}`);
  }

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;

      // Adjust the scroll position threshold as needed
      if (offset > 200) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  // console.log("Checking jobs in home page", jobs)

  return (
    <>
      <Header name="Home" />
      <section className="hero-area">
        <div className="hero-inner">
          <div className="container">
            {emp.id ? (
              <div className="row ">
                <div className="col-lg-12">
                  <div className="inner-content">
                    <div className="hero-text">
                      <h1 className="wow fadeInUp" data-wow-delay=".3s">
                        Get <b className="text-yellow">Your job</b> in minutes
                      </h1>
                      <br />
                      <br />
                      <div className="job-search-form">
                        <a className=" wow fadeInUp" href="/postJob">
                          <div className="submit-btn">
                            <button className="btn" type="submit">
                              We help you to get your best job faster and easier
                            </button>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div
                      className="job-search-wrap-two mt-50 wow fadeInUp"
                      data-wow-delay=".7s"
                    ></div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div
                    className="hero-video-head wow fadeInRight"
                    data-wow-delay=".5s"
                  >
                    <div className="video-inner">
                      <img src={img} alt="#" height="auto" width="100%" />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner-content">
                    <div className="hero-text text-center">
                      <h1
                        className="wow fadeInUp fw-bold font-60 mb-4"
                        data-wow-delay=".3s"
                      >
                        Get <b className="text-yellow">Your job</b> in minutes
                      </h1>
                      <p className="wow fadeInUp fw-bold" data-wow-delay=".3s">
                        We help you to get your best job faster and easier
                      </p>
                    </div>
                    <div
                      className="job-search-wrap-two mt-4 wow fadeInUp"
                      data-wow-delay=".7s"
                    >
                      <div className="row justify-content-center">
                        <div className="col-lg-7 position-relative">
                          <div className={`job-search-form border-0 ${isSticky ? 'home-search-fixed' : ''}`}>
                            <form>
                              <div className="single-field-item keyword mb-3 mb-sm-0">
                                {/* <label for="keyword">What</label> */}
                                {/* <select >
                                  <option></option>
                                </select> */}
                                <input
                                  id="keyword"
                                  className="form-control w-100"
                                  placeholder="Search Jobs"
                                  name="keyword"
                                  type="text"
                                  value={state.text}
                                  onChange={onTextChanged}
                                  autoComplete="off"
                                />
                                {renderSuggestionsTitle()}
                              </div>

                              <div className="single-field-item location  mb-3 mb-sm-0">
                                {/* <label for="location">Where</label> */}
                                <input
                                  id="location"
                                  className="input-field input-field-location form-control w-100"
                                  placeholder="Search Location"
                                  name="location"
                                  type="text"
                                  value={value}
                                  onChange={handleInput}
                                  disabled={!ready}
                                  autoComplete="off"
                                />
                                {status === "OK" && (
                                  <div className="srchList">
                                    <ul>{renderSuggestions()}</ul>
                                  </div>
                                )}
                              </div>

                              <div className="submit-btn  pl-sm-2">
                                <button
                                  className="btn py-2"
                                  type="submit"
                                  onClick={submitForm}
                                >
                                  Find
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row justify-content-center">
                      <div className="col-lg-8 my-md-3">
                        <hr className="text-white" />
                      </div>
                      <div className="col-lg-8">
                        <p className="text-center font-16">
                          Sponsored by{" "}
                          <span className="text-dark px-1 fw-bold">
                            {" "}
                            250{" "}
                          </span>{" "}
                          Companies
                        </p>
                      </div>
                      <div className="col-lg-8">
                        <div className="d-flex align-items-center justify-content-center brands mt-4 flex-wrap">
                          <a href="https://www.exalogic.co/">
                            <img
                              src="https://exalogic-store.s3.us-east-2.amazonaws.com/realcube_cityscape/exalogic.png"
                              className="mr-2"
                              alt="#"
                            />
                          </a>
                          <a href="https://www.myvilla.com/">
                            <img
                              src="https://exalogic-store.s3.us-east-2.amazonaws.com/realcube_cityscape/my-villa.png"
                              className="mr-2"
                              alt="#"
                            />
                          </a>
                          <a href="">
                            <img
                              src="https://exalogic-store.s3.us-east-2.amazonaws.com/realcube_cityscape/tasc.png"
                              className="mr-2"
                              alt="#"
                            />
                          </a>
                          <a href="https://www.sap.com/india/index.html">
                            <img
                              src="https://exalogic-store.s3.us-east-2.amazonaws.com/realcube_cityscape/sap.png"
                              className="mr-2"
                              alt="#"
                            />
                          </a>
                          <a href="ttps://myportal.provis.ae/">
                            <img
                              src="https://exalogic-store.s3.us-east-2.amazonaws.com/realcube_cityscape/provis.png"
                              className="mr-0"
                              alt="#"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="hero-video-head wow fadeInRight" data-wow-delay=".5s">
            <div className="video-inner">
              <img
                src="https://exalogic-store.s3.us-east-2.amazonaws.com/realcube_cityscape/emp-group.png"
                alt="#"
              />
            </div>
          </div>
        </div>
      </section>

      {emp.id ? (
        <section className="apply-process section bg-white">
          <div className="container get-job">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-12">
                <a href="/empregister">
                  {" "}
                  <div className="process-item">
                    <i className="lni lni-user"></i>
                    <h4>Register Your Account</h4>
                    <p>
                      Registering you account gives you access to Job Portal for
                      uploading jobs.
                    </p>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                <a href="/empProfile">
                  {" "}
                  <div className="process-item">
                    <i className="lni lni-book"></i>
                    <h4>Upload Your Profile</h4>
                    <p>
                      Profile can act as a plan for growing business operations
                      and revenue.
                    </p>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                <a href="/postJob">
                  <div className="process-item">
                    <i className="lni lni-briefcase"></i>
                    <h4>Post your Jobs</h4>
                    <p>
                      It is an official advertisement regarding a job opening or
                      vacancy.
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <Apply />
      )}

      {!emp.id && (
        <>
          {/* <Jobcategory /> */}
          {/* <About /> */}

          <section className="find-job job-list section bg-lgray">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12">
                  <h2
                    className="wow fadeInUp font-40  mb-3 mb-md-4 fw-bold"
                    data-wow-delay=".4s"
                  >
                    Top Rated
                  </h2>
                </div>
                <div className="col-12 col-md-auto">
                  <div className="section-title text-start px-0 pb-0 pb-md-2 mb-3 mb-md-4">
                    {/* <span className="wow fadeInDown" data-wow-delay=".2s">
                      Hot Jobs
                    </span> */}

                    <p className="wow fadeInUp" data-wow-delay=".6s">
                      See your opprtunity in the highest job demand around the
                      world
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md text-end   mb-3 mb-md-2">
                  <a href={`/browseJobs`} style={{ cursor: "pointer" }}>
                    <span
                      className="wow fadeInDown font-16"
                      data-wow-delay=".2s"
                    >
                      View all Jobs
                    </span>
                    <img
                      src="https://exalogic-store.s3.us-east-2.amazonaws.com/realcube_cityscape/upload-con.png"
                      className="pl-2"
                    />
                  </a>
                </div>
              </div>
              <div className="single-head">
                <div className="row">
                  {/* {jobs.map((item, index) => {
                    const img = item.employee_logo
                      ? process.env.REACT_APP_API_HOST + item.employee_logo
                      : image;
                    return (
                      <>
                        <div className="col-lg-6 col-12 mb-3" key={index}>
                          <div className="single-job h-100">
                            <div className="job-image">
                              <a href={`/jobDetails/${item.id}`}>
                                <img
                                  src={img}
                                  alt="logo"
                                  height="50"
                                  style={{ marginTop: "5px" }}
                                />
                              </a>
                            </div>
                            <div className="job-content">
                              <h4>
                                <a href={`/jobDetails/${item.id}`}>
                                  {item.title}
                                </a>
                                <br />
                                <a href={`/jobDetails/${item.id}`}>
                                  <span className="mt-2 text-loc lh-21">
                                    {" "}
                                    <i className="lni lni-map-marker"></i>{" "}
                                    {item.city_name}, {item.state_name},{" "}
                                    {item.country_name}
                                  </span>
                                </a>
                              </h4> */}

                              {/* <a href={`/jobDetails/${item.id}`}> */}
                              {/* <p>
                                {item.description &&
                                  item.description.substring(0, 120)}
                              </p> */}
                              {/* </a> */}
                              {/* <ul>
                                <li>
                                  <span className="badge">Full Time</span>
                                </li>
                                <li>
                                  <a href={`/jobDetails/${item.id}`}>
                                    <span className="badge">
                                      {" "}
                                      {item.currency_name} {item.salary_from}-
                                      {item.salary_to}
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href={`${item.employee_website}`}
                                    target="_blank"
                                  >
                                    <span className="badge">
                                      {" "}
                                      {item.employee_website}
                                    </span>
                                  </a>
                                </li>
                              </ul>
                              <div className="job-footer justify-content-between d-flex flex-wrap">
                                <span className="mt-2 text-loc mb-3 mb-sm-0">
                                  Posted: June 6,2022
                                </span>

                                <a
                                  href={`/jobDetails/${item.id}`}
                                  className="btn-yellow"
                                  style={{ cursor: "pointer" }}
                                >
                                  <span
                                    className="wow fadeInDown"
                                    data-wow-delay=".2s"
                                  >
                                    Apply Now
                                  </span>
                                </a>
                              </div>
                            </div>
                            <div className="job-button ">
                              <ul>
                                <li> */}
                                  {/*  <button
                                    style={{
                                      border: "0px",
                                      backgroundColor: "#edf0fd",
                                      color: "#F3B521",
                                      padding: "5px 8px",
                                      borderRadius: "5px",
                                      margin: "2px",
                                    }}
                                    onClick={() => {
                                      bookmarkJobs(item.id);
                                    }}
                                  >
                                    {item.isbookmarkJob ? (
                                      <i className="fa fa-heart"></i>
                                    ) : (
                                      <i className="fa fa-heart-o"></i>
                                    )}
                                    </button>
                                  <button
                                    style={{
                                      border: "0px",
                                      backgroundColor: "#edf0fd",
                                      color: "#F3B521",
                                      padding: "5px 8px",
                                      borderRadius: "5px",
                                      margin: "2px",
                                    }}
                                    onClick={() => {
                                      commentFunction(item.id);
                                    }}
                                  >
                                    <i className="fa fa-commenting-o"></i>
                                  </button> */}

                                  {/* <RWebShare
                                    data={{
                                      text: "Job Portal",
                                      url: `${window.location.protocol}//${window.location.host}/jobDetails/${item.id}`,
                                      title: "Job Portal",
                                    }}
                                  >
                                    <button
                                      style={{
                                        border: "0px",
                                        margin: "2px",
                                        background: "none",
                                      }}
                                    >
                                      <i className="fa fa-share"></i>
                                    </button>
                                  </RWebShare>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })} */}
                  {jobs.map((item, index) => {
                    const img = item.employee_logo
                      ? process.env.REACT_APP_API_HOST + item.employee_logo
                      : image;
                      // console.log("check all the items", item)
                    return (
                      <>
                        <div className="col-lg-6 col-12 mb-3 mb-lg-4" key={index}>
                          <div className="card rounded-5 h-100 job card-hoverable">
                            <div className="card-header bg-white border-bottom job-card">
                            <div className="row job-card-image align-items-center">
                                <div className="col-12 col-sm-auto mb-3 mb-sm-0">
                                  <a href={`/jobDetails/${item.id}`}>
                                    <img
                                      src={img}
                                      alt="logo"
                                      height="50"
                                    />
                                  </a>
                                </div>
                                <div className="col-12 col-sm d-inline-flex justify-content-between align-items-center">
                                  <h6 className="mb-0">
                                    <a href={`/jobDetails/${item.id}`} className="mb-2">
                                      {item.title}
                                    </a>
                                    <br/>
                                    <span className="text-secondary">
                                    {item?.company_name}
                                    </span>
                                    <br/>
                                    <a href={`/jobDetails/${item.id}`}>
                                      <span className="mt-2 text-loc lh-21">
                                        {" "}
                                        {item.city_name ||
                                  item.state_name ||
                                  item.country_name ? (
                                    <i class="lni lni-map-marker"></i>
                                  ) : null}
                                        {item.city_name ? `${item.city_name},`: ''} {item.state_name ? `${item.state_name},`: ''}     
                                       {item.country_name}
                                      </span>
                                    </a>
                                  </h6>
                                {/* </div>
                                <div className="col-auto"> */}
                                  <div className="job-button text-end">
                                  <ul className="mb-0">
                                    <li>
                                      <RWebShare
                                        data={{
                                          text: "Job Portal",
                                          url: `${window.location.protocol}//${window.location.host}/jobDetails/${item.id}`,
                                          title: "Job Portal",
                                        }}
                                      >
                                        <button
                                          style={{
                                            border: "0px",
                                            margin: "2px",
                                            background: "none",
                                          }}
                                        >
                                          <i className="fa fa-share"></i>
                                        </button>
                                      </RWebShare>
                                    </li>
                                  </ul>
                                </div>
                                </div>
                              </div>
                              </div>
                            <div className="card-body job-card">
                              
                              <div className="job-content">
                                <p>
                                  {item.description &&
                                    item.description.substring(0, 120)}
                                </p>
                                <ul>
                                  <li>
                                    <span className="badge">Full Time</span>
                                  </li>
                                  <li>
                                    <a href={`/jobDetails/${item.id}`}>
                                      <span className="badge">
                                        {" "}
                                        {item.currency_name} {currencyFormatter(parseInt(item.salary_from))}-
                                        {currencyFormatter(parseInt(item.salary_to))}
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href={`${item.employee_website}`}
                                      target="_blank"
                                    >
                                      <span className="badge">
                                        {" "}
                                        {item.employee_website}
                                      </span>
                                    </a>
                                  </li>
                                </ul>
                                
                              <hr className="text-gray mt-2"/>
                                <div className="job-footer justify-content-between d-flex flex-wrap">
                                  <span className="mt-2 text-loc mb-3 mb-sm-0">
                                    Posted: June 6,2022
                                  </span>

                                  <a
                                    href={`/jobDetails/${item.id}`}
                                    className="btn-yellow"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <span
                                      className="wow fadeInDown"
                                      data-wow-delay=".2s"
                                    >
                                      Apply Now
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
          <section className="call-action overlay section">
            <div className="">
              <div className="row ">
                <div className="col-lg-7 col-12">
                  <div className="inner bg-yellow m-4 m-sm-0">
                    <div className="section-title">
                      {/* <span className="wow fadeInDown mb-4" data-wow-delay=".2s">
                        GETTING STARTED TO WORK
                      </span> */}
                      <h2 className="wow fadeInUp" data-wow-delay=".4s">
                        Don’t just find. Be found. Put your CV in front of great
                        employers
                      </h2>
                      <p className="wow fadeInUp" data-wow-delay=".6s">
                        It helps you to increase your chances of finding a
                        suitable job and let recruiters contact you about jobs
                        that are not needed to pay for advertising.
                      </p>
                      <div className="button wow fadeInUp" data-wow-delay=".8s">
                        <a href="/addResumeForm" className="btn">
                          <i className="lni lni-upload"></i> Upload Your Resume
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <CounterSection />
          <TalentSection />

          <section className="section testimonials">
            {/* <img
              className="patern1 wow fadeInRight"
              data-wow-delay=".3s"
              src="assets/images/testimonial/patern1.png"
              alt="#"
            />
            <img
              className="patern2 wow fadeInLeft"
              data-wow-delay=".5s"
              src="assets/images/testimonial/patern1.png"
              alt="#"
            /> */}
            <div className="container testimonial-container">
              <div className="row">
                <div className="col-12">
                  <div
                    className="section-title align-left wow fadeInLeft mb-xl-5 mb-lg-4 mb-md-3"
                    data-wow-delay=".3s"
                  >
                    {/* <span>What saye's Our Clients</span> */}
                    <h2 className="font-40 mb-0">Our Testimonials</h2>
                    <p className="mb-4">
                      Stories of jobseekers, who got placed by our System
                    </p>
                  </div>
                  {/* <TestiSliderSection /> */}
                  {/* <div
                    className=" testimonial-inner-head wow fadeInLeft"
                    data-wow-delay=".3s"
                  >
                    <div className=" testimonial-inner">
                      <div className="testimonial-slider">
                        <div
                          id="carouselExampleControls"
                          className="carousel slide"
                          data-ride="carousel"
                        >
                          <div className="carousel-inner overflow-inherit">
                            <div className="carousel-item active">
                              
                            </div>
                            <div className="carousel-item">
                              
                            </div>
                            <div className="carousel-item">
                             
                            </div>
                            <div className="carousel-item">
                              
                            </div>
                          </div>
                          <a
                            className="carousel-control-prev"
                            href="#carouselExampleControls"
                            role="button"
                            data-slide="prev"
                            style={{
                              position: "absolute",
                              bottom: "-300px",
                              left: "38%",
                            }}
                          >
                            <span
                              className="fa fa-long-arrow-left"
                              aria-hidden="true"
                              style={{
                                color: "white",
                                border: "2px solid white",
                                borderRadius: "25px",
                                padding: "8px",
                              }}
                            ></span>
                            <span className="sr-only">Previous</span>
                          </a>
                          <a
                            className="carousel-control-next"
                            href="#carouselExampleControls"
                            role="button"
                            data-slide="next"
                            style={{
                              position: "absolute",
                              bottom: "-300px",
                              right: "38%",
                            }}
                          >
                            <span
                              className="fa fa-long-arrow-right"
                              aria-hidden="true"
                              style={{
                                color: "white",
                                border: "2px solid white",
                                borderRadius: "25px",
                                padding: "8px",
                              }}
                            ></span>
                            <span className="sr-only">Next</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                {/* <div className="col-lg-6 col-12">
                  <div
                    className="testimonial-right wow fadeInRight"
                    data-wow-delay=".5s"
                  >
                    <img
                      src="assets/images/testimonial/testimonial.png"
                      alt="image"
                      style={{ paddingRight: "40px" }}
                      height="500px"
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </section>
          <SubscribeSection />
        </>
      )}

      {/* <Client /> */}
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return { employee: state.employee, candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestEmpLogin,
      requestLogin,
      requestCountLastweekJob,
      requestRecentlyJob,
      requestCategory,
      requestAddBookmark,
      userLogout,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Home);

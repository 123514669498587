import { useEffect } from "react";
import CountUp from 'react-countup';

import WOW from "wowjs";

function CounterSection() {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  return (
    <section class="section py-5 bg-counter">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-sm-4 col-12 mb-3 mb-md-0">
              <div class="card-counter text-center">
                
                <h4 className="font-48 text-white fw-semibold mb-3">
                  <CountUp start={0} end={1}/> <span className="text-yellow"> K+</span></h4>
                <p  className="font-14 text-white lh-1">
                Available Job Vacancy
                </p>
              </div>
          </div>
          <div class="col-lg-4 col-sm-4 col-12  mb-3 mb-md-0">
              <div class="card-counter text-center">
                
                <h4 className="font-48 text-white fw-semibold mb-3"><CountUp start={0} end={0.1} 
decimals={1} decimal={"."}/> <span className="text-yellow">K+</span></h4>
                <p  className="font-14 text-white lh-1">
                Registered Global Company
                </p>
              </div>
          </div>
          <div class="col-lg-4 col-sm-4 col-12">
              <div class="card-counter text-center">
                
                <h4 className="font-48 text-white fw-semibold mb-3"><CountUp start={0} end={10}/> <span className="text-yellow">K+</span></h4>
                <p  className="font-14 text-white lh-1">
                Daily Active User
                </p>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default CounterSection;

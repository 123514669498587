import { useEffect } from "react";

import WOW from "wowjs";

function ApplyProcessSection() {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  return (
    <section className="apply-process section bg-white">
      <div className="container position-relative">
      <div className="get-job p-lg-5 mx-xl-4">
          <div className="row gx-3">
            <div className="col-12 mb-4">
              <div className="hero-text text-center">
                <h2
                  className="wow fadeInUp fw-bold mb-4 font-48"
                  data-wow-delay=".3s"
                >
                  How you will get a job
                </h2>
                <p className="wow fadeInUp fw-bold" data-wow-delay=".3s">
                  Make sure to complete the requirement in every single step
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-12 mb-3 mb-md-0">
              <a className="w-100 h-100" href="/register">
                <div className="card h-100">
                  <div className="card-body  p-xl-5 p-3 text-center">
                    {/* <i className="lni lni-user"></i> */}

                    <img
                      src="https://exalogic-store.s3.us-east-2.amazonaws.com/realcube_cityscape/register-your-account.png"
                      className="mb-4 rounded "
                      alt="#"
                    />
                    <h4 className="font-20 fw-bold mb-3">
                      Register Your Account
                    </h4>
                    <p className="font-14 fw-semibold">
                    Registering your account gives you access to job Portal for searching Jobs.
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-md-4 col-12 mb-3 mb-md-0">
              <a className="w-100 h-100" href="/addResumeForm">
                <div className="card h-100">
                  <div className="card-body  p-xl-5 p-3 text-center">
                    {/* <i className="lni lni-user"></i> */}

                    <img
                      src="https://exalogic-store.s3.us-east-2.amazonaws.com/realcube_cityscape/upload-your-resume.png"
                      className="mb-4 rounded "
                      alt="#"
                    />
                    <h4 className="font-20 fw-bold mb-3">Upload your Resume</h4>
                    <p className="font-14 fw-semibold">
                    Resume is often the first impression a potential employer has of you.
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-md-4 col-12 mb-3 mb-md-0">
              <a className="w-100 h-100" href="/jobList/1/10">
                <div className="card h-100">
                  <div className="card-body  p-xl-5 p-3 text-center">
                    {/* <i className="lni lni-user"></i> */}

                    <img
                      src="https://exalogic-store.s3.us-east-2.amazonaws.com/realcube_cityscape/apply-for-dream-job.png"
                      className="mb-4 rounded "
                      alt="#"
                    />
                    <h4 className="font-20 fw-bold mb-3">
                      Apply for Dream Job
                    </h4>
                    <p className="font-14 fw-semibold">
                    For getting better future, you can apply for your dream job.
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        </div>
    </section>
  );
}
export default ApplyProcessSection;

import { useEffect } from "react";

import WOW from "wowjs";

function SubscribeSection() {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  return (
    <section className="section py-5">
      <div className="container">
      <div className="bg-subscribe">
        <div className="row">
          <div className="col-lg-5 col-md-8 col-12">
            <h2 className="text-white fw-semibold mb-3">Be The First To Hear<br/> The New Job</h2>
            <p  className="font-14 text-white lh-1">
            Available Job Vacancy
            </p>
            <div className="card mt-4 rounded-0">
              <div className="card-body p-2 d-flex align-items-center">
                <input
                                  className="form-control border-0"
                                  placeholder="Your Mail Here"
                                  name="keyword"
                                  type="text"
                                /> 
                <a href="/empregister" className="btn-yellow mb-0 rounded-0">Subscribe</a>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-4 col-12">
            {/* <img src=""/> */}
            </div>
        </div>
        </div>
      </div>
    </section>
  );
}
export default SubscribeSection;

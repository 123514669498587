import React from 'react'

export const currencyFormatter = (amount) => {
    if (amount && amount !== '' && !isNaN(amount)) {
        amount = parseFloat(amount)
        return amount.toLocaleString(undefined, { maximumFractionDigits: 2 })
    }
  return amount
}

export const customLoader = ()=>{
    return(
        <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div> 
    )
}

// export default currencyFormatter